import { Injectable } from '@angular/core';

@Injectable()
export class AuthService
{
	/* -- pCode -- */

	// get access token from storage
	static getAccessToken(): string
	{
		return localStorage.getItem("access_token");
	}

	// register access token to storage
	static setAccessToken(token: string)
	{
		localStorage.setItem("access_token", token);
	}

	// clear access token
	static removeAccessToken()
	{
		localStorage.removeItem("access_token");
	}

	// get refresh token
	static getRefreshToken()
	{
		return localStorage.getItem('refresh_token');
	}

	// register refresh token to storage
	static setRefreshToken(token: string)
	{
		localStorage.setItem("refresh_token", token);
	}

	// clear refresh token
	static removeRefresToken()
	{
		localStorage.removeItem("refresh_token");
	}

	/* -- Azure Active Directory -- */

	// get access token from storage
	static getAzureAdAccessToken(): string
	{
		return localStorage.getItem("azuread_access_token");
	}

	// register access token to storage
	static setAzureAdAccessToken(token: string)
	{
		localStorage.setItem("azuread_access_token", token);
	}

	// clear access token
	static removeAzureAdAccessToken()
	{
		localStorage.removeItem("azuread_access_token");
	}

	// set azure ad last access
	static setAzureAdLastAccess(user: string)
	{
		localStorage.setItem("azuread_lastaccess", user);
	}

	// get azure ad last access
	static getAzureAdLastAccess()
	{
		return localStorage.getItem("azuread_lastaccess");
	}

	// remove azure ad last access
	static removeAzureAdLastAccess()
	{
		localStorage.removeItem("azuread_lastaccess");
	}

	// clear local and session storage
	static clearLocalAndSessionStorage()
	{
		// local storage
		var localStorageItemsToMantain = ['user_lang', 'local_info', 'todos', 'return_url'];
		var localStorageSavedItems = [];
		localStorageItemsToMantain.forEach(item => { localStorageSavedItems.push(localStorage.getItem(item)); });
		localStorage.clear();
		localStorageItemsToMantain.forEach((item, index) =>
		{
			if (localStorageSavedItems[index])
				localStorage.setItem(item, localStorageSavedItems[index]);
		});

		// session storage
		var sessionStorageItemsToMantain = [];
		var sessionStorageSavedItems = [];
		sessionStorageItemsToMantain.forEach(item => { sessionStorageSavedItems.push(sessionStorage.getItem(item)); });
		sessionStorage.clear();
		sessionStorageItemsToMantain.forEach((item, index) =>
		{
			if (sessionStorageSavedItems[index])
				sessionStorage.setItem(item, sessionStorageSavedItems[index]);
		});
	}
}