import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";

const routes: Routes = [

	// admin
	{
		path: "",
		loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)
	},

	// 404 page not found
	//----------------------------------------------------------
	{
		path: "**",
		redirectTo: "admin/notfound"
	}
];

const routerOptions: ExtraOptions =
{
	useHash: false,
	anchorScrolling: 'enabled',
	scrollPositionRestoration: 'enabled'
};

@NgModule({
	imports:
		[
			RouterModule.forRoot(routes, routerOptions)
		],
	exports: [RouterModule]
})
export class AppRoutingModule { }
